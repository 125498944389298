import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import "../styles/personalDetails.scss";
import EmpBasicDetails from '../components/personalDetails/EmpBasicDetails';
import EmergencyContactDetails from '../components/personalDetails/EmergencyContactDetails';
import AddressDetails from '../components/personalDetails/AddressDetails';
import HeaderLayout from '../components/HeaderLayout';
import WishModal from '../components/ModalPopups/WishModal';
import TaskProgressBar from '../components/TaskProgressBar/TaskProgressBar';
import FooterLayout from '../components/FooterLayout';
import { useNavigate } from 'react-router';
import { commonPostservice, commonGetService, fileBaseURL } from '../utils/properties';
import moment from 'moment';
import Toast from '../components/Toaster/Toast';
import { UserDetails } from './Login';
import { CompanyProps } from '../App';
import { ToastDetails } from './Login';
// import { useEmpDetails } from '../components/EmpDetailsContext';

export interface EmpBasicDetailsProps {
    "name": string,
    "date_of_birth": Date | null,
    "gender": string,
    "personal_email_id": string,
    "contact_number": string,
    "marital_status": string,
    "blood_group": string,
    "employee_image_name": string,
    "dobError": boolean,
    "genderError": boolean,
    "contactError": boolean,
    "maritalError": boolean,
    "bloodError": boolean
}

export interface EmpEmergencyDetails {
    "name": string,
    "relation_type": string,
    "contact_number": string,
    "nameError": boolean,
    "relationError": boolean,
    "contactError": boolean
}

export interface EmpAddressDetails {
    "city": string,
    "state": string,
    "pin_code": string,
    "address_proof": string | File | null,
    "address_proof_name": string,
    "address_line_one": string,
    "address_line_two": string,
    "cityError": boolean,
    "stateError": boolean,
    "pinCodeError": boolean,
    "proofError": boolean,
    "proofErrorValue": string,
    "lineOneError": boolean
}

export type HandleData = (compName: string, key: string, val: string | Date | File | null) => void

export interface OptionObject {
    "value": string,
    "title": string
}

interface StateObject {
    "id": string,
    "name": string,
    "country_id": string
}

type CityFunc = (pin: string, type: string) => void

export interface ModalData {
    "modalShow": boolean,
    "empName": string
}


const PersonalDetails = (props: CompanyProps) => {
    const [empImage, setEmpImage] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string>("");
    const [modalData, setModalData] = useState<ModalData>({
        "modalShow": false,
        "empName": ""
    });
    const [empBasicDetails, setEmpBasicDetails] = useState<EmpBasicDetailsProps>({
        "name": "",
        "date_of_birth": null,
        "gender": "",
        "personal_email_id": "",
        "contact_number": "",
        "marital_status": "",
        "blood_group": "",
        "employee_image_name": "",
        "dobError": false,
        "genderError": false,
        "contactError": false,
        "maritalError": false,
        "bloodError": false
    });
    const [empEmergencyDetails, setEmpEmergencyDetails] = useState<EmpEmergencyDetails>({
        "name": "",
        "relation_type": "",
        "contact_number": "",
        "nameError": false,
        "relationError": false,
        "contactError": false
    });
    const [empCurrentAddress, setEmpCurrentAddress] = useState<EmpAddressDetails>({
        "city": "",
        "state": "",
        "pin_code": "",
        "address_proof": null,
        "address_proof_name": "",
        "address_line_one": "",
        "address_line_two": "",
        "cityError": false,
        "stateError": false,
        "pinCodeError": false,
        "proofError": false,
        "proofErrorValue": "",
        "lineOneError": false
    });
    const [empPermanentAddress, setEmpPermanentAddress] = useState<EmpAddressDetails>({
        "city": "",
        "state": "",
        "pin_code": "",
        "address_proof": null,
        "address_proof_name": "",
        "address_line_one": "",
        "address_line_two": "",
        "cityError": false,
        "stateError": false,
        "pinCodeError": false,
        "proofError": false,
        "proofErrorValue": "",
        "lineOneError": false
    });
    const [isSameAddress, setIsSameAddress] = useState<string>("0");
    const [profileError, setProfileError] = useState<string>("");
    const [isFilled, setIsFilled] = useState<boolean>(true);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [stateOptions, setStateOptions] = useState<OptionObject[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [toastDetails, setToastDetails] = useState<ToastDetails>({ message: '', toastShow: false, status: '' })

    const Navigate = useNavigate();
    const fileInputOne = useRef<HTMLInputElement | null>(null)
    const fileInputTwo = useRef<HTMLInputElement | null>(null)
    // const empDetails = useEmpDetails();

    useEffect(() => {
        const userDetails: UserDetails | undefined = getProfileInfo();
        if (userDetails) {
            setModalData((prevState) => {
                return {
                    ...prevState,
                    modalShow: Object.entries(userDetails?.completed_steps)?.every(([key, value]) => value === false),
                    empName: userDetails?.name
                }
            })
            getPersonalDetails(userDetails?.name, userDetails?.userMail);
            getStateNames();
        }
    }, [])

    useEffect(() => {
        if (isSameAddress === "1") {
            handleCloneAddress();
        }

    }, [empCurrentAddress])

    useEffect(() => {
        if (isSameAddress === "1") {
            handleCloneAddress();
        }
        else {
            setEmpPermanentAddress({
                "city": "",
                "state": "",
                "pin_code": "",
                "address_proof": null,
                "address_proof_name": "",
                "address_line_one": "",
                "address_line_two": "",
                "cityError": false,
                "stateError": false,
                "pinCodeError": false,
                "proofError": false,
                "proofErrorValue": "",
                "lineOneError": false
            })
        }
    }, [isSameAddress])

    useEffect(() => {
        if (empCurrentAddress?.pin_code?.length === 6) {
            getCityDetails(empCurrentAddress?.pin_code, "current");
        }
    }, [empCurrentAddress?.pin_code])

    useEffect(() => {
        if (isSameAddress !== "1" && empPermanentAddress?.pin_code?.length === 6) {
            getCityDetails(empPermanentAddress?.pin_code, "permanent");
        }
    }, [empPermanentAddress?.pin_code])

    const handleCloneAddress = () => {
        let newAddress = {}
        Object.entries(empCurrentAddress)?.map(([key, val]) => {
            newAddress = {
                ...newAddress,
                [key]: val
            }
        })

        setEmpPermanentAddress((prevState) => {
            return {
                ...prevState,
                ...newAddress
            };
        })
    }

    const getCityDetails: CityFunc = (pin, type) => {
        commonGetService(`onboard/pincode?pincode=${pin}`).then(res => {
            if (res.status) {
                if (type === "current") {
                    setEmpCurrentAddress((prevState) => {
                        return {
                            ...prevState,
                            city: res?.data?.district,
                            state: res?.data?.state,
                            cityError: false,
                            stateError: false
                        }
                    })
                }
                else {
                    setEmpPermanentAddress((prevState) => {
                        return {
                            ...prevState,
                            city: res?.data?.district,
                            state: res?.data?.state,
                            cityError: false,
                            stateError: false
                        }
                    })
                }
            }
            else {
                if (type === "current") {
                    setEmpCurrentAddress((prevState) => {
                        return {
                            ...prevState,
                            city: "",
                            state: ""
                        }
                    })
                }
                else {
                    setEmpPermanentAddress((prevState) => {
                        return {
                            ...prevState,
                            city: "",
                            state: ""
                        }
                    })
                }
            }
        })
    }

    const getProfileInfo = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const userDetails: UserDetails = JSON.parse(userDetailsString);
            return userDetails;
        }
    }

    const getStateNames = () => {
        commonGetService(`onboard/state`).then(item => {
            if (item) {
                let stateArray = item?.states?.map((state: StateObject) => {
                    return {
                        value: state?.name,
                        title: state?.name
                    }
                })

                setStateOptions(stateArray);
            }
        })
    }

    const getPersonalDetails = (userName: string, userEmail: string) => {
        setIsLoading(true);
        commonGetService(`onboard/get_details/personal`).then(res => {
            setIsLoading(false);
            if (res.status) {
                if (res?.data?.data) {
                    const responseValue = res?.data?.data

                    setEmpBasicDetails({
                        ...empBasicDetails,
                        "name": userName,
                        "date_of_birth": new Date(responseValue?.date_of_birth),
                        "gender": responseValue?.gender,
                        "personal_email_id": userEmail,
                        "contact_number": responseValue?.contact_number,
                        "marital_status": responseValue?.marital_status,
                        "blood_group": responseValue?.blood_group,
                        "employee_image_name": responseValue?.employee_image_name
                    })
                    setEmpEmergencyDetails({ ...empEmergencyDetails, ...responseValue?.emergency_contact_details });
                    setEmpCurrentAddress({ ...empCurrentAddress, ...responseValue?.current_address });
                    setEmpPermanentAddress({ ...empPermanentAddress, ...responseValue?.permanent_address });
                    setPreviewUrl(responseValue?.employee_image);
                    setIsSameAddress(responseValue?.same_address ? "1" : "0");
                    setIsEdit(true);
                }
                else {
                    setEmpBasicDetails({
                        ...empBasicDetails,
                        "name": userName,
                        "personal_email_id": userEmail
                    })
                }
            }
            else {
                setEmpBasicDetails({
                    ...empBasicDetails,
                    "name": userName,
                    "personal_email_id": userEmail
                })
            }
        })
    }

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maxFileSize = 800 * 1024 //800kb in bytes
        const selectedFile = e.target.files ? e.target.files[0] : null;

        if (selectedFile) {
            if (selectedFile?.size > maxFileSize) {
                setEmpImage(null);
                setPreviewUrl("");
                setEmpBasicDetails({ ...empBasicDetails, employee_image_name: "" });
                setProfileError("File size is too big");
                return;
            }

            setEmpImage(selectedFile);

            const objectUrl = URL.createObjectURL(selectedFile);
            setPreviewUrl(objectUrl);
            setEmpBasicDetails({ ...empBasicDetails, employee_image_name: selectedFile.name })
            setProfileError("");
        }
    }

    const handleBasicDetailsChange: HandleData = useCallback((compName, keyName, val) => {
        if (compName === "empBasicDetails") {
            let errorField = keyName === "date_of_birth" ? "dobError" : keyName === "gender" ? "genderError" :
                keyName === "contact_number" ? "contactError" : keyName === "marital_status" ? "maritalError" : "bloodError"
            setEmpBasicDetails((prevState) => {
                return {
                    ...prevState,
                    [keyName]: val,
                    [errorField]: false
                }
            })
        }
    }, [empBasicDetails])


    const handleEmergencyDetailsChange: HandleData = useCallback((compName, keyName, val) => {
        if (compName === "empEmergencyDetails") {
            let errorField = keyName === "name" ? "nameError" : keyName === "relation_type" ? "relationError" : "contactError"
            setEmpEmergencyDetails((prevState) => {
                return {
                    ...prevState,
                    [keyName]: val,
                    [errorField]: false
                }
            })
        }
    }, [empEmergencyDetails])

    const handleCurrentAddressChange: HandleData = useCallback((compName, keyName, val) => {
        if (compName === "currentAddress") {
            let errorField = keyName === "city" ? "cityError" : keyName === "state" ? "stateError" :
                keyName === "pin_code" ? "pinCodeError" : (keyName === "address_proof" || keyName === "proofErrorValue") ? "proofError" : "lineOneError"
            if (typeof val === "string") {
                setEmpCurrentAddress((prevState) => {
                    return {
                        ...prevState,
                        [keyName]: val,
                        proofErrorValue: keyName === "proofErrorValue" ? val : "",
                        [errorField]: keyName !== "proofErrorValue" ? false : true
                    }
                })
            }
            else {
                setEmpCurrentAddress((prevState) => {
                    return {
                        ...prevState,
                        proofErrorValue: "",
                        [keyName]: val,
                        [errorField]: false
                    }
                })
            }
        }
    }, [empCurrentAddress])

    const handlePermanentAddressChange: HandleData = useCallback((compName, keyName, val) => {
        if (compName === "permanentAddress") {
            let errorField = keyName === "city" ? "cityError" : keyName === "state" ? "stateError" :
                keyName === "pin_code" ? "pinCodeError" : (keyName === "address_proof" || keyName === "proofErrorValue") ? "proofError" : "lineOneError"
            if (typeof val === "string") {
                setEmpPermanentAddress((prevState) => {
                    return {
                        ...prevState,
                        [keyName]: val,
                        proofErrorValue: keyName === "proofErrorValue" ? val : "",
                        [errorField]: keyName !== "proofErrorValue" ? false : true
                    }
                })
            }
            else {
                setEmpPermanentAddress((prevState) => {
                    return {
                        ...prevState,
                        proofErrorValue: "",
                        [keyName]: val,
                        [errorField]: false
                    }
                })
            }
        }
    }, [empPermanentAddress])

    const childCallBack = () => {
        setModalData((prevState) => {
            return {
                ...prevState,
                modalShow: false
            }
        })
    }

    const handleValidate = () => {
        let isValid = true;
        // Temporary error states
        const basicDetailsErrors = { ...empBasicDetails };
        const emergencyDetailsErrors = { ...empEmergencyDetails };
        const currentAddressErrors = { ...empCurrentAddress };
        const permanentAddressErrors = { ...empPermanentAddress };
        let profileImageError = "";

        // Validate basic details
        if (!empBasicDetails?.date_of_birth) {
            basicDetailsErrors.dobError = true;
            isValid = false;
        }
        if (empBasicDetails?.gender === "") {
            basicDetailsErrors.genderError = true;
            isValid = false;
        }
        if (empBasicDetails?.contact_number.trim() === "") {
            basicDetailsErrors.contactError = true;
            isValid = false;
        }
        if (empBasicDetails?.contact_number.trim() !== "" && empBasicDetails?.contact_number.trim().length < 10) {
            basicDetailsErrors.contactError = true;
            isValid = false;
        }
        if (empBasicDetails?.marital_status === "") {
            basicDetailsErrors.maritalError = true;
            isValid = false;
        }
        if (empBasicDetails?.blood_group === "") {
            basicDetailsErrors.bloodError = true;
            isValid = false;
        }

        // Validate profile image
        if (previewUrl === "") {
            profileImageError = "Please upload the profile photo";
            isValid = false;
        }

        // Validate emergency contact details
        if (empEmergencyDetails?.relation_type === "") {
            emergencyDetailsErrors.relationError = true;
            isValid = false;
        }
        if (empEmergencyDetails?.name?.trim() === "") {
            emergencyDetailsErrors.nameError = true;
            isValid = false;
        }
        if (empEmergencyDetails?.contact_number.trim() === "") {
            emergencyDetailsErrors.contactError = true;
            isValid = false;
        }
        if (empEmergencyDetails?.contact_number.trim() !== "" && empEmergencyDetails?.contact_number.trim().length < 10) {
            emergencyDetailsErrors.contactError = true;
            isValid = false;
        }

        // Validate current address
        if (empCurrentAddress?.city.trim() === "") {
            currentAddressErrors.cityError = true;
            isValid = false;
        }
        if (empCurrentAddress?.state.trim() === "") {
            currentAddressErrors.stateError = true;
            isValid = false;
        }
        if (empCurrentAddress?.pin_code.trim() === "") {
            currentAddressErrors.pinCodeError = true;
            isValid = false;
        }
        if (empCurrentAddress?.address_proof === "" || empCurrentAddress?.address_proof === null) {
            currentAddressErrors.proofError = true;
            currentAddressErrors.proofErrorValue = "";
            isValid = false;
        }
        if (empCurrentAddress?.address_line_one.trim() === "") {
            currentAddressErrors.lineOneError = true;
            isValid = false;
        }

        // Validate permanent address
        if (empPermanentAddress?.city.trim() === "") {
            permanentAddressErrors.cityError = true;
            isValid = false;
        }
        if (empPermanentAddress?.state.trim() === "") {
            permanentAddressErrors.stateError = true;
            isValid = false;
        }
        if (empPermanentAddress?.pin_code.trim() === "") {
            permanentAddressErrors.pinCodeError = true;
            isValid = false;
        }
        if (empPermanentAddress?.address_proof === "" || empPermanentAddress?.address_proof === null) {
            permanentAddressErrors.proofError = true;
            permanentAddressErrors.proofErrorValue = "";
            isValid = false;
        }
        if (empPermanentAddress?.address_line_one.trim() === "") {
            permanentAddressErrors.lineOneError = true;
            isValid = false;
        }

        // Update state once
        setEmpBasicDetails(basicDetailsErrors);
        setEmpEmergencyDetails(emergencyDetailsErrors);
        setEmpCurrentAddress(currentAddressErrors);
        setEmpPermanentAddress(permanentAddressErrors);
        setProfileError(profileImageError);

        return isValid;
    }

    const handleSave = () => {
        if (handleValidate()) {
            let permanentAddress = isSameAddress === "1" ? empCurrentAddress : empPermanentAddress
            let current_address = {
                "city": empCurrentAddress?.city,
                "state": empCurrentAddress?.state,
                "pin_code": empCurrentAddress?.pin_code,
                "address_line_one": empCurrentAddress?.address_line_one,
                "address_line_two": empCurrentAddress?.address_line_two,
                "current_address_proof_name": empCurrentAddress?.address_proof_name
            }
            let permanent_address = {
                "city": permanentAddress?.city,
                "state": permanentAddress?.state,
                "pin_code": permanentAddress?.pin_code,
                "address_line_one": permanentAddress?.address_line_one,
                "address_line_two": permanentAddress?.address_line_two,
                "permanent_address_proof_name": permanentAddress?.address_proof_name
            }
            let emergency_contact_details = {
                "name": empEmergencyDetails?.name,
                "relation_type": empEmergencyDetails?.relation_type,
                "contact_number": empEmergencyDetails?.contact_number
            }
            let formData = new FormData();

            formData.append("name", empBasicDetails?.name);
            formData.append("gender", empBasicDetails?.gender);
            formData.append("blood_group", empBasicDetails?.blood_group);
            formData.append("same_address", isSameAddress);
            formData.append("date_of_birth", moment(empBasicDetails?.date_of_birth).format("YYYY-MM-DD"));
            formData.append("contact_number", empBasicDetails?.contact_number);
            formData.append("marital_status", empBasicDetails?.marital_status);
            formData.append("current_address", JSON.stringify(current_address));
            formData.append("permanent_address", JSON.stringify(permanent_address));
            formData.append("personal_email_id", empBasicDetails?.personal_email_id);
            formData.append("employee_image_name", empBasicDetails?.employee_image_name);
            formData.append("emergency_contact_details", JSON.stringify(emergency_contact_details));
            empImage ? formData.append("employee_image", empImage) : formData.append("employee_image", previewUrl);
            empCurrentAddress?.address_proof && formData.append("current_address_proof", empCurrentAddress?.address_proof);
            permanentAddress?.address_proof && formData.append("permanent_address_proof", permanentAddress?.address_proof);
            empCurrentAddress?.address_proof && formData.append("current_address_proof_name", empCurrentAddress?.address_proof_name);
            permanentAddress?.address_proof && formData.append("permanent_address_proof_name", permanentAddress?.address_proof_name);

            setIsLoading(true);
            commonPostservice(`onboard/form_update/personal_details`, formData).then(res => {
                setIsLoading(false);
                if (res.status) {
                    const userDetails: UserDetails | undefined = getProfileInfo();
                    if (userDetails) {
                        userDetails.completed_steps.personal_details = true;
                        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
                        Navigate('/education');
                    }
                }
                else {
                    // alert('error occured in API');

                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        setToastDetails((prevState) => {
                            return { ...prevState, message: 'error occured in API', toastShow: true, status: 'error' }
                        })
                    }, 500)
                    setTimeout(() => {
                        setToastDetails((prevState) => {
                            return { ...prevState, message: '', toastShow: false, status: '' }
                        })
                    }, 1500)
                }
            })
        }
        else {
            window.scrollTo(0, 0);
            setTimeout(() => {
                setToastDetails((prevState) => {
                    return { ...prevState, message: 'Please fill in all required fields', toastShow: true, status: 'error' }
                })
            }, 500)
            setTimeout(() => {
                setToastDetails((prevState) => {
                    return { ...prevState, message: '', toastShow: false, status: '' }
                })
            }, 1500)
        }
    }

    return (
        <HeaderLayout companyLogo={props?.companyDetails?.logo}>
            <div className='pg-out-container'>
                <div className='pg-in-container'>
                    <TaskProgressBar empName={empBasicDetails?.name} />
                    <div className='pnl-details d-flex flex-column'>
                        <h2 className='pg-heading'>Personal Details</h2>
                        <EmpBasicDetails empBasicDetails={empBasicDetails} handleData={handleBasicDetailsChange} />
                        <div className='d-flex flex-column pto-upload'>
                            <h6>Upload photo <span>*</span></h6>
                            <div className={`d-flex flex-row file-cont ${previewUrl ? 'align-items-center' : ''}`}>
                                {previewUrl !== "" ?
                                    <>
                                        <Image src={previewUrl} alt="Image" className='emp-image' />
                                        <Button className='upload-btn' onKeyDown={(e) => e.key === 'Enter' && fileInputOne?.current?.click()}>
                                            <label className="custom-file-label" htmlFor="file-input">Upload New Image</label>
                                            <input type="file" ref={fileInputOne} id="file-input" className="file-input" accept=".jpg, .jpeg, .png" onChange={(e) => { handleImageUpload(e) }} />
                                        </Button>
                                        <Button variant="dark" className='remove-btn' onClick={() => { setEmpImage(null); setPreviewUrl("") }}>Remove</Button>
                                    </> :
                                    <>
                                        <div className='d-flex justify-content-center align-items-center img-avatar'>
                                            <p>{empBasicDetails?.name?.charAt(0)}</p>
                                        </div>
                                        <div className='d-flex flex-column custom-file-input'>
                                            <div>
                                                <Button onKeyDown={(e) => e.key === 'Enter' && fileInputTwo?.current?.click()}>
                                                    <label className="custom-file-label" htmlFor="file-input">Choose File</label>
                                                    <input type="file" ref={fileInputTwo} id="file-input" className="file-input" accept=".jpg, .jpeg, .png" onChange={(e) => { handleImageUpload(e) }} />
                                                </Button>
                                                <span className="file-name" id="file-name">No file chosen</span>
                                            </div>
                                            <p>Supported Format: JPG or PNG. Max size<br /> of 800K</p>
                                        </div>
                                    </>
                                }
                            </div>
                            {profileError !== "" && <Form.Text id="empMail">{profileError}</Form.Text>}
                        </div>
                        <EmergencyContactDetails empEmergencyDetails={empEmergencyDetails} handleData={handleEmergencyDetailsChange} />
                        <div className='current_address'>
                            <h5>Current Address</h5>
                            <AddressDetails
                                empAddress={empCurrentAddress}
                                handleData={handleCurrentAddressChange}
                                addressType='currentAddress'
                                stateOptions={stateOptions}
                            />
                        </div>
                        <div className='current_address'>
                            <h5>Permanent Address</h5>
                            <Form.Check
                                label="Permanent Address is the same as Current Address"
                                name="permanent_address"
                                type="checkbox"
                                value={isSameAddress}
                                checked={isSameAddress === "1"}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setIsSameAddress(isSameAddress === "0" ? "1" : "0")
                                    }
                                }}
                                onChange={(e) => {
                                    setIsSameAddress(e.target.checked ? "1" : "0");
                                }}
                                id="pt-address"
                            />
                            <AddressDetails
                                empAddress={empPermanentAddress}
                                handleData={handlePermanentAddressChange}
                                addressType='permanentAddress'
                                isDisable={isSameAddress === "1"}
                                stateOptions={stateOptions}
                            />
                        </div>
                    </div>
                    <FooterLayout handleSave={handleSave} isDisable={isLoading} />
                </div>
            </div>
            {toastDetails?.toastShow && <Toast message={toastDetails?.message} status={toastDetails?.status} />}
            <WishModal modalData={modalData} callBack={childCallBack} companyName={props?.companyDetails?.name} />
        </HeaderLayout>
    );
};

export default PersonalDetails;