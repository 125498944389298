import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Calendar from "../datePicker/Calendar";
import FileUpload from "../fileUpload/FileUpload";
import Toast from "../Toaster/Toast";
import Select from "../select/Select";
import "../../styles/professionaldetail.scss";
import TaskProgressBar from "../TaskProgressBar/TaskProgressBar";
import FooterLayout from "../FooterLayout";
import { commonGetService, commonPostservice } from "../../utils/properties";
import moment, { min } from "moment";
import { useNavigate } from "react-router-dom";
import { set } from "react-datepicker/dist/date_utils";
import { UserDetails } from "../../pages/Login";


interface DependenceDetail {
    dependentName: string;
    dependentRelationship: string;
    dependentdob: Date | null;
    dependentBloodGroup: string;
    dependentOccupation: string;
}
interface AadharDetails {
    aadharName: string;
    aadharNumber?: number | undefined | string;
    aadharFile?: File | null;
    aadharFileName?: string;
}
interface PanDetails {
    panName: string;
    panNumber?: number | undefined | string;
    panFile?: File | null;
    panFileName?: string;
}

interface BankDetails {
    primaryAccount: BankAccount;
    secondaryAccount: BankAccount;
}
interface BankAccount {
    accountHolderName: string;
    accountNumber: string;
    ifsc: string;
    bankName: string;
    branchName: string;
    accountFile: File | null;
    accountFileName?: string;
}

interface PassportDetails {
    passportNumber?: number | string;
    issueDate?: Date | null;
    expiryDate?: Date | null;
    passportFile?: File | null;
    passportFileName?: string;
}


const DocumentDetails = () => {
    const Navigate = useNavigate();

    const stateCodes = [
        "02", "12", "13", "10", "22", "30", "24", "06", "07", "20",
        "29", "32", "23", "11", "14", "15", "16", "17", "21", "34",
        "03", "08", "33", "36", "09", "05", "19"
    ];

    const isValidStateCode = (input: string) => {
        const firstTwoChars = input.substring(0, 2); // Extract the first two characters
        return stateCodes.includes(firstTwoChars);
    };

    const [dependentDetails, setDependantDetails] = useState<DependenceDetail[]>([
        {
            dependentName: "",
            dependentRelationship: "",
            dependentdob: null,
            dependentBloodGroup: "",
            dependentOccupation: "",
        },
    ]);
    const [aadharDetails, setAadharDetails] = useState<AadharDetails>({
        aadharName: "",
        aadharNumber: "",
        aadharFile: null,
        aadharFileName: ""
    });

    const [panDetails, setPanDetails] = useState<PanDetails>({
        panName: "",
        panNumber: undefined,
        panFile: null,
        panFileName: ""
    });
    const [bankDetails, setBankDetails] = useState<BankDetails>({
        primaryAccount: {
            accountHolderName: "",
            accountNumber: "",
            ifsc: "",
            bankName: "",
            branchName: "",
            accountFile: null,
            accountFileName: ""
        },
        secondaryAccount: {
            accountHolderName: "",
            accountNumber: "",
            ifsc: "",
            bankName: "",
            branchName: "",
            accountFile: null,
            accountFileName: ""

        },
    });
    const [passportDetails, setPassportDetails] = useState<PassportDetails>({
        passportNumber: "",
        issueDate: null,
        expiryDate: null,
        passportFile: null,
        passportFileName: ""
    })
    const [uanNumber, setUanNumber] = useState("");
    const [esiNumber, setEsiNumber] = useState("");
    const [toast, showToast] = useState<boolean>(false);
    const [toastMsg, setToastMsg] = useState<string>("");
    const [uanError, setUanError] = useState<boolean>(false);
    const [esiError, setEsiError] = useState<boolean>(false);

    // Dependent Details Error State
    const [dependentDetailsErrors, setDependentDetailsErrors] = useState<
        {
            dependentName?: string;
            dependentRelationship?: string;
            dependentdob?: string;
            dependentBloodGroup?: string;
            dependentOccupation?: string;
        }[]
    >([]);

    // Aadhar Details Error State
    const [aadharDetailsErrors, setAadharDetailsErrors] = useState<{
        aadharName?: string;
        aadharNumber?: string;
        aadharFile?: string;
    }>({});

    // PAN Details Error State
    const [panDetailsErrors, setPanDetailsErrors] = useState<{
        panName?: string;
        panNumber?: string;
        panFile?: string;
    }>({});

    // Bank Details Error State
    const [bankDetailsErrors, setBankDetailsErrors] = useState<{
        primaryAccount?: {
            accountHolderName?: string;
            accountNumber?: string;
            ifsc?: string;
            bankName?: string;
            branchName?: string;
            accountFile?: string;
            accountFileName?: string;
        };
        secondaryAccount?: {
            accountHolderName?: string;
            accountNumber?: string;
            ifsc?: string;
            bankName?: string;
            branchName?: string;
            accountFile?: string;
            accountFileName?: string;

        };
        general?: string;
    }>({});

    // Passport Details Error State
    const [passportDetailsErrors, setPassportDetailsErrors] = useState<{
        passportNumber?: string;
        issueDate?: string;
        expiryDate?: string;
        passportFile?: string;
    }>({});

    const [isLoading, setIsLoading] = useState<boolean>(true)

    let Relationships = [
        {
            value: "",
            title: "Select",
        },
        {
            value: "Father",
            title: "Father",
        },
        {
            value: "Mother",
            title: "Mother",
        },
        { value: "Spouse", title: "Spouse" },
        { value: "Children", title: "Children" },
        { value: "Siblings", title: "Siblings" },
    ];
    let BloodGroups = [
        {
            value: "",
            title: "Select",
        },
        { value: "A+", title: "A+" },
        { value: "B+", title: "B+" },
        { value: "O+", title: "O+" },
        { value: "AB+", title: "AB+" },
        { value: "A-", title: "A-" },
        { value: "B-", title: "B-" },
        { value: "O-", title: "O-" },
        { value: "AB-", title: "AB-" },
    ];
    const getFilteredRelationships = (currentIndex: number) => {
        const selectedRelationships = dependentDetails
            .map((dep, index) => (index !== currentIndex ? dep.dependentRelationship : null))
            .filter(Boolean); // Get all relationships except for the current dependent

        return Relationships.filter(
            (relationship) => !selectedRelationships.includes(relationship.value)
        );
    };


    // const handleDependants = () => {
    //     if (dependentDetails.length < 5) {
    //         setDependantDetails((prevWorkExperiences) => [
    //             ...prevWorkExperiences,
    //             {
    //                 dependentName: "",
    //                 dependentRelationship: "",
    //                 dependentdob: null,
    //                 dependentBloodGroup: "",
    //                 dependentOccupation: "",
    //             },
    //         ]);
    //         setDependentDetailsErrors((prevErrors) => [
    //             ...prevErrors,
    //             {
    //                 dependentName: "",
    //                 dependentRelationship: "",
    //                 dependentdob: "",
    //                 dependentBloodGroup: "",
    //                 dependentOccupation: "",
    //             },
    //         ]);
    //     } else {
    //         showToast(true);
    //         setToastMsg("You can add a maximum of 5 dependents. To add more, please contact your HR.");
    //         setTimeout(() => {
    //             showToast(false);
    //             setToastMsg("");
    //         }, 4500);
    //     }
    // };

    const removeExperience = (index: number) => {
        const updatedExperiences = dependentDetails.filter((_, i) => i !== index);
        setDependantDetails(updatedExperiences);

        const updatedErrors = dependentDetailsErrors.filter((_, i) => i !== index);
        setDependentDetailsErrors(updatedErrors);
    };

    const handleChange = (
        index: number,
        field: keyof DependenceDetail,
        value: string | boolean
    ) => {
        if (value === "" && field === "dependentName") {
            setDependantDetails((prevExperiences) => {
                return prevExperiences.map((exp, i) =>
                    i === index ? {
                        dependentName: "",
                        dependentRelationship: "",
                        dependentdob: null,
                        dependentBloodGroup: "",
                        dependentOccupation: "",
                    } : exp
                );
            })

            setDependentDetailsErrors((prevErrors) => {
                return prevErrors.map((exp, i) =>
                    i === index
                        ? {
                            dependentName: "",
                            dependentRelationship: "",
                            dependentdob: "",
                            dependentBloodGroup: "",
                            dependentOccupation: "",
                        }
                        : exp
                );
            });
            return

        }
        value = typeof value === "string" && value.startsWith(" ") ? value.trim() : value;

        setDependantDetails((prevExperiences) => {


            return prevExperiences.map((exp, i) =>
                i === index ? { ...exp, [field]: value } : exp
            );
        });
        setDependentDetailsErrors((prevErrors) => {
            return prevErrors.map((exp, i) =>
                i === index ? { ...exp, [field]: "" } : exp
            );
        })
    };





    // 
    const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

    const handleBankDetailChange = (
        type: keyof BankDetails,
        field: keyof BankAccount,
        value: string
    ) => {
        if (field === "accountNumber" && (/^\d*$/.test(value)) && value.length <= 18) {

            setBankDetails((prevDetails) => {
                const updatedDetails = {
                    ...prevDetails,
                    [type]: { ...prevDetails[type], [field]: value },
                };

                // Compare account numbers
                const { primaryAccount, secondaryAccount } = updatedDetails;
                const areAccountNumbersSame =
                    primaryAccount.accountNumber === secondaryAccount.accountNumber &&
                    primaryAccount.accountNumber.length > 0; // Ensure non-empty account numbers

                // Set error if account numbers are the same
                setBankDetailsErrors((prevErrors) => ({
                    ...prevErrors,
                    general: areAccountNumbersSame
                        ? "Primary and secondary account numbers cannot be the same."
                        : "",
                }));

                return updatedDetails; // Return updated details
            });
        } else if (field === "ifsc") {
            value = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase().substring(0, 11);
            setBankDetails((prevDetails) => ({
                ...prevDetails,
                [type]: { ...prevDetails[type], [field]: value.trim() },
            }));
        }
        else if (field !== "accountNumber") {
            value = value.startsWith(" ") ? value.trim() : value;

            setBankDetails((prevDetails) => ({
                ...prevDetails,
                [type]: { ...prevDetails[type], [field]: value },
            }));

        }
        setBankDetailsErrors((prevErrors) => ({
            ...prevErrors,
            [type]: { ...prevErrors[type], [field]: "" },
        }))
    }

    const onsubmit = () => {

        if (validateAll()) {
            setIsLoading(true)
            const formData = new FormData();

            if (aadharDetails?.aadharFile) formData.append("aadharFile", aadharDetails.aadharFile);
            if (panDetails?.panFile) formData.append("panFile", panDetails.panFile);
            if (passportDetails?.passportFile) formData.append("passportFile", passportDetails.passportFile);
            if (bankDetails?.primaryAccount?.accountFile) formData.append("primaryAccountFile", bankDetails.primaryAccount.accountFile);
            if (bankDetails?.secondaryAccount?.accountFile) formData.append("secondaryAccountFile", bankDetails.secondaryAccount.accountFile);

            if (aadharDetails?.aadharFileName) formData.append("aadharFileName", aadharDetails.aadharFileName);
            if (panDetails?.panFileName) formData.append("panFileName", panDetails.panFileName);
            if (passportDetails?.passportFileName) formData.append("passportFileName", passportDetails.passportFileName);
            if (bankDetails?.primaryAccount?.accountFileName) formData.append("primaryAccountFileName", bankDetails.primaryAccount.accountFileName);
            if (bankDetails?.secondaryAccount?.accountFileName) formData.append("secondaryAccountFileName", bankDetails.secondaryAccount.accountFileName);

            let aadhar_details = {
                aadharName: aadharDetails.aadharName.trim(),
                aadharNumber: aadharDetails.aadharNumber?.toString()?.replace(/\s+/g, ""),
                aadharFileName: aadharDetails.aadharFileName
            };
            let pan_details = { panName: panDetails.panName.trim(), panNumber: panDetails.panNumber, panFileName: panDetails.panFileName };
            let bank_details = {
                primaryAccount: {
                    accountHolderName: bankDetails?.primaryAccount?.accountHolderName.trim(),
                    accountNumber: bankDetails?.primaryAccount?.accountNumber,
                    ifsc: bankDetails?.primaryAccount?.ifsc,
                    bankName: bankDetails?.primaryAccount?.bankName,
                    branchName: bankDetails?.primaryAccount?.branchName,
                    accounFile: bankDetails?.primaryAccount?.accountFile,
                    accountFileName: bankDetails?.primaryAccount?.accountFileName

                },
                secondaryAccount: {
                    accountHolderName: bankDetails?.secondaryAccount?.accountHolderName.trim(),
                    accountNumber: bankDetails?.secondaryAccount?.accountNumber,
                    ifsc: bankDetails?.secondaryAccount?.ifsc,
                    bankName: bankDetails?.secondaryAccount?.bankName,
                    branchName: bankDetails?.secondaryAccount?.branchName,
                    accounFile: bankDetails?.secondaryAccount?.accountFile,
                    accountFileName: bankDetails?.secondaryAccount?.accountFileName

                },
            };
            let passport_details = {
                passportNumber: passportDetails.passportNumber,
                issueDate: moment(passportDetails.issueDate).format("DD-MM-YYYY"),
                expiryDate: moment(passportDetails.expiryDate).format("DD-MM-YYYY"),
                passportFileName: passportDetails.passportFileName
            };
            let experiences: DependenceDetail[] = [];
            if (
                dependentDetails.length >= 1 &&
                dependentDetails.some((detail) => detail.dependentName?.trim() !== "")
            ) {
                experiences = dependentDetails
                    .filter(
                        (detail) =>
                            detail.dependentName && detail.dependentName.trim() !== ""
                    )
                    .map((experience) => ({
                        ...experience,
                        dependentDob: moment(experience.dependentdob).format("DD-MM-YYYY"),
                        dependentName: experience.dependentName.trim(),
                    }));
            }

            let dependent_details = experiences;

            formData.append("aadhar_details", JSON.stringify(aadhar_details));
            formData.append("pan_details", JSON.stringify(pan_details));
            formData.append("bank_details", JSON.stringify(bank_details));
            passport_details?.passportNumber?.toString() !== "" && passport_details?.passportNumber && formData.append("passport_details", JSON.stringify(passport_details));
            dependent_details.length > 0 && formData.append("dependent_details", JSON.stringify(dependent_details));
            esiNumber && formData.append("esi_number", esiNumber.trim());

            uanNumber && formData.append("uan_number", uanNumber.trim());

            commonPostservice(`onboard/form_update/document_details`, formData).then(res => {

                setIsLoading(false);
                if (res.status) {
                    const userDetails: UserDetails | undefined = getProfileInfo();
                    if (userDetails) {
                        userDetails.completed_steps.document_details = true;
                        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
                        Navigate("/declaration");
                    }


                }
                else {
                    showToast(true);
                    setToastMsg("something went wrong please try again")
                    window.scrollTo(0, 0);

                    setTimeout(() => {
                        showToast(false)
                        setToastMsg("")


                    }, 2000)
                }
            })
        } else {
            window.scrollTo(0, 0);
            showToast(true);
            setToastMsg("Please fill all the details")

            setTimeout(() => {
                showToast(false)
                setToastMsg("")


            }, 2000)
            return
        }
    }

    const getProfileInfo = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const userDetails: UserDetails = JSON.parse(userDetailsString);
            return userDetails;
        }
    }

    const validateAll = () => {
        const isAadharValid = validateAadharDetails();
        const isDependentValid = validateDependentDetails();
        const isBankValid = validateBankDetails();
        const isPassportValid = passportDetails.passportNumber?.toString() !== "" && passportDetails.passportNumber ? validatePassportDetails() : true;
        const isPanValid = validatePanDetails();

        if (uanNumber && uanNumber?.length !== 12) {
            setUanError(true);
            return isAadharValid && isDependentValid && isBankValid && isPassportValid && isPanValid && false;
        } else if (esiNumber && (esiNumber.length !== 17 || !isValidStateCode(esiNumber))) {
            setEsiError(true);
            return isAadharValid && isDependentValid && isBankValid && isPassportValid && isPanValid && false;
        }
        return isAadharValid && isDependentValid && isBankValid && isPassportValid && isPanValid;
    };

    const validateAadharDetails = () => {
        let errors: typeof aadharDetailsErrors = {};

        if (!aadharDetails.aadharName) {
            errors.aadharName = "Aadhar name is required.";
        }
        if (!/^\d{12}$/.test(aadharDetails.aadharNumber?.toString()?.replace(/\s+/g, '') || "")) {
            errors.aadharNumber = "Aadhar number must be a 12-digit number.";
        }

        if (!aadharDetails.aadharFile) {
            errors.aadharFile = "Aadhar file is required.";
        }

        setAadharDetailsErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };

    const validateDependentDetails = () => {
        const errors = dependentDetails.map((dependent, index) => {
            const error: {
                dependentName?: string;
                dependentRelationship?: string;
                dependentdob?: string;
                dependentBloodGroup?: string;
                dependentOccupation?: string;
            } = {};

            // Skip validation entirely if dependentName is empty
            if (!dependent.dependentName) {
                return error; // No need to validate further
            }

            // Validate other fields if dependentName is present
            if (!dependent.dependentRelationship) {
                error.dependentRelationship = "Relationship is required.";
            }

            if (!dependent.dependentdob) {
                error.dependentdob = "Date of birth is required.";
            }

            if (!dependent.dependentBloodGroup) {
                error.dependentBloodGroup = "Blood group is required.";
            }

            if (!dependent.dependentOccupation) {
                error.dependentOccupation = "Occupation is required.";
            }

            return error;
        });

        setDependentDetailsErrors(errors);
        return errors.every((err) => Object.keys(err).length === 0); // Return true if no errors
    };

    const validateBankDetails = () => {
        let errors: typeof bankDetailsErrors = {};

        // Validate Primary Account
        if (!bankDetails.primaryAccount.accountHolderName) {
            errors.primaryAccount = { ...(errors.primaryAccount || {}), accountHolderName: "Account holder name is required." };
        }

        if (!/^\d+$/.test(bankDetails.primaryAccount.accountNumber) || bankDetails.primaryAccount.accountNumber.length < 9) {
            errors.primaryAccount = { ...(errors.primaryAccount || {}), accountNumber: "Account number is required." };
        }

        if (!bankDetails.primaryAccount.ifsc) {
            errors.primaryAccount = { ...(errors.primaryAccount || {}), ifsc: "IFSC code is required." };
        } else if (! /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/.test(bankDetails.primaryAccount.ifsc)) {
            errors.primaryAccount = { ...(errors.primaryAccount || {}), ifsc: "Enter valid IFSC code ." };
        }
        if (!bankDetails.primaryAccount.bankName) {
            errors.primaryAccount = { ...(errors.primaryAccount || {}), bankName: "Bank Name is required." };
        }

        if (!bankDetails.primaryAccount.branchName) {
            errors.primaryAccount = { ...(errors.primaryAccount || {}), branchName: "Branch Name is required." };
        }

        if (!bankDetails.primaryAccount.accountFile) {
            errors.primaryAccount = { ...(errors.primaryAccount || {}), accountFile: "File is required." };
        }

        // Validate Secondary Account
        if (!bankDetails.secondaryAccount.accountHolderName) {
            errors.secondaryAccount = { ...(errors.secondaryAccount || {}), accountHolderName: "Account holder name is required." };
        }

        if (!/^\d+$/.test(bankDetails.secondaryAccount.accountNumber) || bankDetails.secondaryAccount.accountNumber.length < 9) {
            errors.secondaryAccount = { ...(errors.secondaryAccount || {}), accountNumber: "Account number is required." };
        }

        if (!bankDetails.secondaryAccount.ifsc) {
            errors.secondaryAccount = { ...(errors.secondaryAccount || {}), ifsc: "IFSC code is required." };
        } else if (! /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/.test(bankDetails.secondaryAccount.ifsc)) {
            errors.secondaryAccount = { ...(errors.secondaryAccount || {}), ifsc: "Enter valid IFSC code ." };
        }

        if (!bankDetails.secondaryAccount.bankName) {
            errors.secondaryAccount = { ...(errors.secondaryAccount || {}), bankName: "Bank Name is required." };
        }

        if (!bankDetails.secondaryAccount.branchName) {
            errors.secondaryAccount = { ...(errors.secondaryAccount || {}), branchName: "Branch Name is required." };
        }

        if (!bankDetails.secondaryAccount.accountFile) {
            errors.secondaryAccount = { ...(errors.secondaryAccount || {}), accountFile: "File is required." };
        }

        if (bankDetails.primaryAccount.accountNumber && bankDetails.secondaryAccount.accountNumber) {    // Check if Primary and Secondary Accounts are the same
            if (
                bankDetails.primaryAccount.accountNumber === bankDetails.secondaryAccount.accountNumber
            ) {

                errors.general = "Primary and Secondary accounts cannot be the same.";

            }
        }

        setBankDetailsErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };


    const validatePassportDetails = () => {
        let errors: typeof passportDetailsErrors = {};

        if (!passportDetails.passportNumber) {
            errors.passportNumber = "Passport number is required.";
        }
        if (passportDetails.passportNumber && !(/^[A-Z][0-9]{7}$/.test(passportDetails.passportNumber?.toString()))) {
            errors.passportNumber = "Enter Valid Passport Number.";
        }

        if (!passportDetails.issueDate) {
            errors.issueDate = "Issue date is required.";
        }

        if (!passportDetails.expiryDate) {
            errors.expiryDate = "Expiry date is required.";
        }
        if (!passportDetails.passportFile) {
            errors.passportFile = "File is required.";
        }

        setPassportDetailsErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };

    const validatePanDetails = () => {
        let errors: typeof panDetailsErrors = {};

        // Validate PAN Name
        if (!panDetails.panName) {
            errors.panName = "PAN name is required.";
        }

        // Validate PAN Number
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // Standard PAN format (e.g., ABCDE1234F)
        if (!panDetails.panNumber) {
            errors.panNumber = "PAN number is required.";
        } else if (!panRegex.test(panDetails.panNumber.toString())) {
            errors.panNumber = "Invalid PAN number format.";
        }

        // Validate PAN File
        if (!panDetails.panFile) {
            errors.panFile = "PAN file is required.";
        }

        setPanDetailsErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };


    // Usage



    const dependentDateChange = (date: Date | null, index: number, label: string) => {
        setDependantDetails((prevExperiences) => {
            return prevExperiences.map((exp, i) =>
                i === index ? { ...exp, [label]: date } : exp
            );
        });
    }

    useEffect(() => {
        commonGetService(`onboard/get_details/document`).then(res => {
            setIsLoading(false);
            if (res.status) {
                // Extract the personal details step
                if (res.data) {
                    let aadhar = {
                        aadharName: res.data.data.aadhar_details.aadharName,
                        aadharNumber: setAadharNumber(res.data.data.aadhar_details.aadharNumber),
                        aadharFile: res.data.data.aadhar_details.aadharFile,
                        aadharFileName: res.data.data.aadhar_details.aadharFileName
                    }
                    setAadharDetails(aadhar)
                    setPanDetails(res.data.data.pan_details)
                    let passportDetails = { ...res.data.data.passport_details }
                    const parseDate = (dateString: string | null): Date | null => {
                        if (!dateString) return null;
                        const [day, month, year] = dateString.split("-").map(Number);
                        return new Date(year, month - 1, day); // Adjust month to 0-indexed
                    };

                    const updatedPassportDetails = {
                        ...passportDetails,
                        issueDate: parseDate(passportDetails.issueDate),
                        expiryDate: parseDate(passportDetails.expiryDate),
                        passportFile: passportDetails.passportFile,
                        passportFileName: passportDetails.passportFileName
                    };

                    setPassportDetails(updatedPassportDetails);
                    // setBankDetails(res.data.data.bank_details)
                    setBankDetails({
                        primaryAccount: {
                            accountHolderName: res.data.data.bank_details.primaryAccount.accountHolderName,
                            accountNumber: res.data.data.bank_details.primaryAccount.accountNumber,
                            ifsc: res.data.data.bank_details.primaryAccount.ifsc,
                            bankName: res.data.data.bank_details.primaryAccount.bankName,
                            branchName: res.data.data.bank_details.primaryAccount.branchName,
                            accountFile: res.data.data.bank_details.primaryAccount.accountFile,
                            accountFileName: res.data.data.bank_details.primaryAccount.accountFileName, // Set file name if available
                        },
                        secondaryAccount: {
                            accountHolderName: res.data.data.bank_details.secondaryAccount.accountHolderName,
                            accountNumber: res.data.data.bank_details.secondaryAccount.accountNumber,
                            ifsc: res.data.data.bank_details.secondaryAccount.ifsc,
                            bankName: res.data.data.bank_details.secondaryAccount.bankName,
                            branchName: res.data.data.bank_details.secondaryAccount.branchName,
                            accountFile: res.data.data.bank_details.secondaryAccount.accountFile,
                            accountFileName: res.data.data.bank_details.secondaryAccount.accountFileName, // Set file name if available
                        },
                    });
                    setEsiNumber(res.data.data.esi_number)
                    setUanNumber(res.data.data.uan_number)

                    const dependentDetails = res?.data?.data?.dependent_details ?? [{
                        dependentName: "",
                        dependentRelationship: "",
                        dependentdob: null,
                        dependentBloodGroup: "",
                        dependentOccupation: "",
                    }]; // default to empty array if null or undefined

                    // Function to normalize the date string to "YYYY-MM-DD"
                    const normalizeDateString = (dateString: string | null): string | null => {
                        if (!dateString) return null;

                        // Check if the format is "DD-MM-YYYY"
                        const parts = dateString.split("-");
                        if (parts.length === 3) {
                            // If format is "DD-MM-YYYY", convert it to "YYYY-MM-DD"
                            return `${parts[2]}-${parts[1]}-${parts[0]}`;
                        }

                        // If it's already in "YYYY-MM-DD" format, no need to change
                        return dateString;
                    };

                    const updatedDependentDetails = dependentDetails.map((item: any) => {
                        // Normalize the date string
                        const normalizedDate = normalizeDateString(item.dependentDob);

                        // Parse the normalized date string into a valid Date object
                        const validDate = normalizedDate && !isNaN(Date.parse(normalizedDate))
                            ? new Date(normalizedDate)
                            : null;

                        return {
                            ...item,
                            dependentdob: validDate,
                        };
                    });
                    setDependantDetails(updatedDependentDetails);

                }
            } else { }
        })
    }, [])
    const handleAadharInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        // Remove all spaces and non-numeric characters
        value = value.replace(/\D/g, "");

        // Limit to 12 digits
        if (value.length > 12) {
            value = value.slice(0, 12);
        }

        // Insert spaces after every digit
        value = value.match(/.{1,4}/g)?.join("  ") || "";
        setAadharDetails({ ...aadharDetails, aadharNumber: value });
        setAadharDetailsErrors({ ...aadharDetailsErrors, aadharNumber: "" });
    };

    const setAadharNumber = (value: string) => {
        if (value.length > 12) {
            value = value.slice(0, 12);
        }

        // Insert spaces after every digit
        value = value.match(/.{1,4}/g)?.join("  ") || "";
        return value
    }

    return (
        <div className="container-work">
            <TaskProgressBar empName={'Vikrant'} />
            {toast && (
                <Toast
                    message={
                        toastMsg
                    }
                    status={"error"}
                />
            )}
            <div className="professionaldetail">

                <h1>Document Details</h1>

                <div className="experience-section d-flex justify-content-between align-items-center">
                    <h4 className={`experience-title `}> Aadhar Details</h4>
                </div>
                <LabelledInput
                    label="Name as per Aadhar"
                    required
                    value={aadharDetails.aadharName}
                    onChange={(e) => {
                        setAadharDetails({ ...aadharDetails, aadharName: e.target.value.startsWith(" ") ? e.target.value.trim() : e.target.value });
                        setAadharDetailsErrors({ ...aadharDetailsErrors, aadharName: "" });
                    }
                    }
                    className={
                        aadharDetailsErrors.aadharName ? "error-msg" : ""
                    }
                />
                {aadharDetailsErrors?.aadharName && <Form.Text className="color-red">{aadharDetailsErrors?.aadharName}</Form.Text>}
                <LabelledInput
                    label="Aadhar Number"
                    required
                    value={aadharDetails.aadharNumber?.toString() ?? ""}
                    onChange={
                        handleAadharInputChange
                    }
                    className={
                        aadharDetailsErrors.aadharNumber ? "error-msg" : ""
                    }
                />{aadharDetailsErrors?.aadharNumber && <Form.Text className="color-red">{aadharDetailsErrors?.aadharNumber}</Form.Text>}
                <LabelledInput
                    label="Upload Aadhar"
                    required
                    type="file"
                    value={""}
                    onChange={
                        () => { }

                    }
                    fileValue={aadharDetails.aadharFile}
                    fileName={aadharDetails.aadharFileName ?? ""}
                    fileChange={(file) => {
                        setAadharDetails({ ...aadharDetails, aadharFile: file });
                        setAadharDetailsErrors({ ...aadharDetailsErrors, aadharFile: "" });
                    }}
                    className={
                        aadharDetailsErrors.aadharFile ? "error-msg" : ""
                    }
                />
                {aadharDetailsErrors?.aadharFile && <Form.Text className="color-red">{aadharDetailsErrors?.aadharFile}</Form.Text>}

                <hr></hr>
                <div className="experience-section d-flex justify-content-between align-items-center">
                    <h4 className={`experience-title `}> PAN Details</h4>
                </div>
                <LabelledInput
                    label=" Name as per PAN"
                    required
                    value={panDetails.panName}
                    onChange={(e) => {
                        setPanDetails({ ...panDetails, panName: e.target.value.startsWith(" ") ? e.target.value.trim() : e.target.value });
                        setPanDetailsErrors({ ...panDetailsErrors, panName: "" });
                    }
                    }
                    className={
                        panDetailsErrors.panName ? "error-msg" : ""
                    }
                />
                {panDetailsErrors?.panName && <Form.Text className="color-red">{panDetailsErrors?.panName}</Form.Text>}
                <LabelledInput
                    label=" PAN Number"
                    required
                    value={panDetails.panNumber?.toString() ?? ""}
                    onChange={(e) => {
                        let value = e.target.value;

                        // Remove special characters and convert to uppercase
                        value = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase().substring(0, 10);

                        setPanDetails({ ...panDetails, panNumber: value });
                        setPanDetailsErrors({ ...panDetailsErrors, panNumber: "" });
                    }
                    }
                    className={
                        panDetailsErrors.panNumber ? "error-msg" : ""
                    }
                />
                {panDetailsErrors?.panNumber && <Form.Text className="color-red">{panDetailsErrors?.panNumber}</Form.Text>}

                <LabelledInput
                    label="Upload Pan card"
                    required
                    type="file"
                    value={""}
                    onChange={() => { }

                    }
                    fileValue={panDetails.panFile}
                    fileName={panDetails.panFileName ?? ""}

                    fileChange={(file) => {
                        setPanDetails({ ...panDetails, panFile: file });
                        setPanDetailsErrors({ ...panDetailsErrors, panFile: "" });
                    }}
                    className={
                        panDetailsErrors.panFile ? "error-msg" : ""
                    }

                />
                {panDetailsErrors?.panFile && <Form.Text className="color-red">{panDetailsErrors?.panFile}</Form.Text>}
                <hr></hr>
                <div className="experience-section d-flex justify-content-between align-items-center">
                    <h4 className={`experience-title `}> Passport Details (Optional)</h4>
                </div>
                <LabelledInput label=" Passport number" value={passportDetails?.passportNumber?.toString() ?? ""} onChange={(e) => {
                    if (e.target.value === "") {
                        setPassportDetailsErrors({});
                        setPassportDetails({ passportNumber: "", issueDate: null, expiryDate: null, passportFile: null, passportFileName: "" });
                    } else {
                        let value = e.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase().substring(0, 8);
                        setPassportDetails({ ...passportDetails, passportNumber: value })
                    }
                }} />
                <div className="d-flex flex-row justify-content-between gap-1 w-100">
                    <div className="w-50">
                        <LabelledInput
                            label="Issue Date"
                            value={passportDetails?.issueDate as Date}
                            onChange={() => { }}
                            type="date"
                            dateChange={(date) => {
                                setPassportDetails({ ...passportDetails, issueDate: date });
                                setPassportDetailsErrors({ ...passportDetailsErrors, issueDate: "" });
                            }}
                            disabled={!passportDetails?.passportNumber}
                            className={
                                passportDetailsErrors?.issueDate ? "error-msg" : ""
                            }
                            maxDate={passportDetails?.expiryDate as Date}
                            isError={!passportDetailsErrors?.issueDate ? false : true}

                        />

                    </div>
                    <div className="w-50">
                        <LabelledInput
                            label="Expiry Date"
                            value={passportDetails?.expiryDate as Date}
                            onChange={() => { }}
                            type="date"
                            dateChange={(date) => {
                                setPassportDetails({ ...passportDetails, expiryDate: date });
                                setPassportDetailsErrors({ ...passportDetailsErrors, expiryDate: "" });
                            }}
                            disabled={!passportDetails?.passportNumber}
                            minDate={passportDetails?.issueDate as Date}
                            className={
                                passportDetailsErrors?.expiryDate ? "error-msg" : ""
                            }
                            isError={!passportDetailsErrors?.expiryDate ? false : true}

                        />

                    </div>
                </div>
                <LabelledInput
                    label="Upload Passport"
                    required={passportDetails?.passportNumber ? true : false}
                    type="file"
                    value={""}
                    onChange={
                        () => { }
                        // setAadharDetails({ ...aadharDetails, aadharNumber: e.target.value })
                    }
                    fileValue={passportDetails.passportFile}
                    fileName={passportDetails.passportFileName ?? ""}

                    fileChange={(file) => {
                        setPassportDetails({ ...passportDetails, passportFile: file });
                        setPassportDetailsErrors({ ...passportDetailsErrors, passportFile: "" });
                    }}
                    disabled={!passportDetails?.passportNumber}
                    className={
                        passportDetailsErrors?.passportFile ? "error-msg" : ""
                    }



                />
                {(passportDetailsErrors?.passportFile) && <Form.Text className="color-red">{passportDetailsErrors?.passportFile}</Form.Text>}

                {["primaryAccount", "secondaryAccount"].map((accountType) => (
                    <>
                        <hr></hr>
                        <div className="experience-section d-flex justify-content-between align-items-center">
                            <h4 className={`experience-title `}>
                                {" "}
                                {`${accountType === "primaryAccount" ? "Primary" : "Secondary"
                                    } Bank Details`}
                            </h4>
                        </div>
                        {["accountHolderName", "accountNumber", "ifsc"].map((field) => (
                            <>
                                <LabelledInput
                                    key={field}
                                    label={field === "ifsc" ? "IFSC code" : capitalize(field.replace(/([A-Z])/g, " $1"))}
                                    required
                                    value={bankDetails[accountType as keyof BankDetails][field as keyof BankAccount] as string}
                                    onChange={(e) =>
                                        handleBankDetailChange(
                                            accountType as keyof BankDetails,
                                            field as keyof BankAccount,
                                            e.target.value
                                        )
                                    }
                                    className={
                                        (field === "accountNumber" && bankDetailsErrors.general) ||
                                            bankDetailsErrors[accountType as keyof BankDetails]?.[field as keyof BankAccount]
                                            ? "error-msg"
                                            : ""
                                    }

                                />
                                {(field === "accountNumber" && bankDetailsErrors.general) ? (
                                    <Form.Text className="color-red">{bankDetailsErrors.general}</Form.Text>
                                ) : (
                                    bankDetailsErrors[accountType as keyof BankDetails]?.[field as keyof BankAccount] && (
                                        <Form.Text className="color-red">
                                            {bankDetailsErrors[accountType as keyof BankDetails]?.[field as keyof BankAccount]}
                                        </Form.Text>
                                    )
                                )}
                            </>

                        ))}
                        <div className="d-flex flex-row justify-content-between gap-1 w-100">
                            <div className="w-50">
                                <LabelledInput
                                    label=" Bank Name"
                                    required
                                    value={
                                        bankDetails[accountType as keyof BankDetails]["bankName"]
                                    }
                                    onChange={(e) =>
                                        handleBankDetailChange(
                                            accountType as keyof BankDetails,
                                            "bankName",
                                            e.target.value
                                        )
                                    }
                                    className={
                                        bankDetailsErrors[accountType as keyof BankDetails]?.["bankName"]
                                            ? "error-msg"
                                            : ""
                                    }
                                />
                                {bankDetailsErrors[accountType as keyof BankDetails]?.["bankName"] && <Form.Text className="color-red">{bankDetailsErrors[accountType as keyof BankDetails]?.["bankName"]}</Form.Text>}

                            </div>
                            <div className="w-50">
                                <LabelledInput
                                    label=" Branch Name"
                                    required
                                    value={
                                        bankDetails[accountType as keyof BankDetails]["branchName"]
                                    }
                                    onChange={(e) =>
                                        handleBankDetailChange(
                                            accountType as keyof BankDetails,
                                            "branchName",
                                            e.target.value
                                        )
                                    }
                                    className={
                                        bankDetailsErrors[accountType as keyof BankDetails]?.["branchName"]
                                            ? "error-msg"
                                            : ""
                                    }
                                />
                                {bankDetailsErrors[accountType as keyof BankDetails]?.["branchName"] && <Form.Text className="color-red">{bankDetailsErrors[accountType as keyof BankDetails]?.["branchName"]}</Form.Text>}

                            </div>
                        </div>
                        <LabelledInput
                            label="Upload Bank Passbook/Cancelled Cheque"
                            required
                            type="file"
                            value={""}
                            onChange={
                                () => { }
                            }
                            fileValue={bankDetails[accountType as keyof BankDetails]["accountFile"]}
                            fileName={bankDetails[accountType as keyof BankDetails]["accountFileName"] ?? ""}
                            fileChange={(file) => {
                                setBankDetails({ ...bankDetails, [accountType as keyof BankDetails]: { ...bankDetails[accountType as keyof BankDetails], accountFile: file } })
                                setBankDetailsErrors({ ...bankDetailsErrors, [accountType as keyof BankDetails]: { ...bankDetailsErrors[accountType as keyof BankDetails], accountFile: "" } })
                            }
                            }
                            className={
                                bankDetailsErrors[accountType as keyof BankDetails]?.["accountFile"]
                                    ? "error-msg"
                                    : ""
                            }

                        />
                        {bankDetailsErrors[accountType as keyof BankDetails]?.["accountFile"] && <Form.Text className="color-red">{bankDetailsErrors[accountType as keyof BankDetails]?.["accountFile"]}</Form.Text>}
                    </>
                ))}

                <hr></hr>
                {/* UAN and ESI Details */}
                <SectionTitle title="UAN Details (Optional)" />
                <LabelledInput
                    label="UAN number"
                    value={uanNumber}
                    onChange={(e) => {
                        let value = e.target.value
                            .replace(/[^0-9]/g, "")
                            .substring(0, 12);
                        setUanNumber(value);
                        setUanError(false);
                    }}
                    className={
                        uanError ? "error-msg" : ""}
                />
                {uanError && <Form.Text className="color-red">{"Enter Valid UAN Number"}</Form.Text>}

                <hr></hr>
                <SectionTitle title="ESI Details (Optional)" />
                <LabelledInput
                    label="ESI number"
                    value={esiNumber}
                    onChange={(e) => {
                        let value = e.target.value
                            .replace(/[^0-9]/g, "")
                            .substring(0, 17);
                        setEsiNumber(value);
                        setEsiError(false);
                    }}
                    className={
                        esiError ? "error-msg" : ""}
                />
                {esiError && <Form.Text className="color-red">{"Enter Valid ESI Number"}</Form.Text>}

                <hr></hr>
                {/* Dependent Details */}
                <h4 className={`experience-title `}>
                    Dependent Details (Optional)
                </h4>
                <div className="d-flex statement-condition">
                    <p>
                        Note: You can add a maximum of 5 dependents. To add more, please
                        contact your HR.
                    </p>
                </div>
                {dependentDetails.length > 0 && dependentDetails.map((exp, index) => (
                    <>
                        <div className="experience-section d-flex justify-content-between align-items-center">
                            <h4 className={`experience-title dependent-title`}>
                                Dependent - {index + 1 === 1 ? "1  (Optional)" : index + 1}
                            </h4>
                            {dependentDetails.length > 1 && (
                                <Button
                                    className="remove-experience d-flex align-items-center"
                                    onClick={() => removeExperience(index)}
                                >
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_525_4366)">
                                            <path
                                                d="M5.33203 4.09491L5.33203 2.98058C5.33203 2.20405 5.33203 1.81579 5.56498 1.57455C5.79792 1.33331 6.17284 1.33331 6.92267 1.33331L9.07472 1.33331C9.82456 1.33331 10.1995 1.33331 10.4324 1.57455C10.6654 1.81579 10.6654 2.20405 10.6654 2.98058V4.09491"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                            />
                                            <path
                                                d="M9.66797 7.81012L6.33464 7.81012"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M8.66797 10.4769L7.33464 10.4769"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M11.9955 10.3399C11.896 11.2403 11.8267 11.8595 11.7252 12.3325C11.6267 12.7917 11.5122 13.0364 11.3675 13.2133C11.2419 13.3668 11.0933 13.5 10.9269 13.6079C10.7352 13.7323 10.4794 13.8193 10.0121 13.8668C9.53092 13.9157 8.9078 13.9166 8.00199 13.9166C7.09619 13.9166 6.47306 13.9157 5.99186 13.8668C5.52461 13.8193 5.26879 13.7323 5.07707 13.6079C4.91067 13.5 4.76204 13.3668 4.63648 13.2133C4.49181 13.0364 4.37727 12.7917 4.27874 12.3325C4.17727 11.8595 4.10797 11.2403 4.00853 10.3399L3.34919 4.37024L12.6548 4.37024L11.9955 10.3399Z"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                            />
                                            <path
                                                d="M2 4.0813H14"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_525_4366">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    &nbsp; Remove
                                </Button>
                            )}
                        </div>


                        <p>
                            <label className={`label `}> Dependent name</label>
                        </p>
                        <Form.Control
                            type="text"
                            placeholder=""
                            value={exp.dependentName}
                            onChange={(e) =>
                                handleChange(index, "dependentName", e.target.value)
                            }
                            className={
                                dependentDetailsErrors[index]?.dependentName ? "error-msg" : ""
                            }
                        />
                        {(dependentDetailsErrors[index]?.dependentName) && <Form.Text className="color-red">{dependentDetailsErrors[index]?.dependentName}</Form.Text>}

                        <p>
                            <label className={`label`}> Relationship </label>
                        </p>
                        <Select
                            value={exp.dependentRelationship}
                            options={getFilteredRelationships(index)}
                            onChange={(value) => handleChange(index, "dependentRelationship", value)}
                            placeholder="Select Relationship"
                            width="100%"
                            margin="0 0"
                            disabled={exp.dependentName === '' && true}
                            className={
                                dependentDetailsErrors[index]?.dependentRelationship ? "error-msg" : ""
                            }
                        />
                        {(dependentDetailsErrors[index]?.dependentRelationship) && <Form.Text className="color-red">{dependentDetailsErrors[index]?.dependentRelationship}</Form.Text>}

                        <div className="d-flex flex-row justify-content-between gap-1 w-100 n-drop">
                            <div className="w-50">
                                {/* <LabelledInput
                                    label="Date of Birth"
                                    value={""}
                                    onChange={() => { }}
                                    type="date"
                                /> */}
                                <Calendar selectedDate={exp.dependentdob} dateUpdate={(date: Date | null) => {

                                    if (date !== null && dependentDateChange) {
                                        dependentDateChange(date, index, 'dependentdob');
                                    }

                                }} dpType={'date'} label={'Date of Birth'} isDisable={exp.dependentName === '' && true}
                                    isError={dependentDetailsErrors[index]?.dependentdob ? true : false}
                                    maxDate={new Date()}
                                />
                                {(dependentDetailsErrors[index]?.dependentdob) && <Form.Text className="color-red">{dependentDetailsErrors[index]?.dependentdob}</Form.Text>}

                                {/* {error && exp.to === null && <Form.Text className="color-red">Enter valid  from date</Form.Text>} */}
                            </div>
                            <div className="w-50">
                                <p>
                                    <label className={`label `}>Blood Group</label>
                                </p>
                                <Select
                                    value={exp.dependentBloodGroup}
                                    options={BloodGroups}
                                    onChange={(value) => handleChange(index, "dependentBloodGroup", value)}
                                    placeholder="Select One"
                                    width="100%"
                                    margin="0"
                                    disabled={exp.dependentName === '' && true}
                                    className={
                                        dependentDetailsErrors[index]?.dependentBloodGroup ? "error-msg" : ""
                                    }
                                />
                                {(dependentDetailsErrors[index]?.dependentBloodGroup) && <Form.Text className="color-red">{dependentDetailsErrors[index]?.dependentBloodGroup}</Form.Text>}

                            </div>
                        </div>
                        <p>
                            <label className={`label `}> Occupation</label>
                        </p>
                        <Form.Control
                            type="text"
                            placeholder=""
                            value={exp.dependentOccupation}
                            onChange={(e) =>
                                handleChange(index, "dependentOccupation", e.target.value)
                            }
                            disabled={exp.dependentName === '' && true}
                            className={
                                dependentDetailsErrors[index]?.dependentOccupation ? "error-msg" : ""
                            }
                        />
                        {(dependentDetailsErrors[index]?.dependentOccupation) && <Form.Text className="color-red">{dependentDetailsErrors[index]?.dependentOccupation}</Form.Text>}

                        {/* <hr></hr> */}
                    </>
                ))}
                {/* <button className={`add-employment-button `} onClick={handleDependants}>
                    + &nbsp; Add Dependent{" "}
                </button> */}
            </div>
            <FooterLayout handleSave={onsubmit} isDisable={isLoading} />
        </div>
    );
};
export const SectionTitle = ({ title }: { title: string }) => (
    <div className="experience-section d-flex justify-content-between align-items-center">
        <h4 className="experience-title">{title}</h4>
    </div>
);
export const LabelledInput = React.memo(({
    label,
    required = false,
    value,
    onChange,
    type,
    fileValue = null,
    fileChange,
    dateChange,
    disabled = false,
    className = "",
    fileName = "",
    minDate = undefined,
    maxDate = undefined, isError = false
}: {
    label: string;
    required?: boolean;
    value: string | Date | null;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type?: string;
    fileValue?: File | null;
    fileChange?: (file: File | null) => void;
    dateChange?: (date: Date | null) => void;
    disabled?: boolean
    className?: string
    fileName?: string
    minDate?: Date | undefined
    maxDate?: Date | undefined
    isError?: boolean
}) => (
    <>
        {type !== "date" && <p>
            <label className="label">
                {label} {required && <span className="red-star">*</span>}
            </label>
        </p>}
        {type ? (
            type === "date" ? (
                <Calendar selectedDate={value instanceof Date ? value : null}
                    dateUpdate={(date: Date | null) => {
                        if (date !== null && dateChange) {
                            dateChange(date);
                        }

                    }} dpType={'date'} label={label} isDisable={disabled} minDate={minDate} maxDate={maxDate} isError={isError} />

            ) : (
                type === "file" &&
                <FileUpload
                    fileValue={fileValue}
                    fileName={fileName}
                    callBack={(file: File | null) => {
                        if (typeof file !== "string" && fileChange) { // Add a check here
                            fileChange(file);
                        }

                    }
                    }
                    isDisable={disabled}
                />

            )
        ) : (
            <Form.Control
                key={label}
                type="text"
                placeholder=""
                value={value as string}
                onChange={onChange}
                disabled={disabled}
                className={className}
            />
        )}
    </>
));

export default DocumentDetails;
